import React, {useLayoutEffect } from 'react';
import NewsAccordionItem from './NewsAccordionItem.js';
const NewsAccordionContent = ({ content, dataTitle, accordionHooveringHandler, TodaysDate, simpleSlug, intialLoadActive, state}) => {
console.log("Content"+content.length);
console.log("simpleSlug"+simpleSlug);
const accordionRef = React.createRef();

useLayoutEffect(() => {
    // Add a custom perf marker (shows up in chrome's performance trace)

   if(accordionRef.current !== null){ 
        if (state) {
            accordionRef.current.classList.add("active");
        } else {
            accordionRef.current.classList.remove("active");
        }
    }

  }, [accordionRef, state]);
// state ? 'dropdown-content accordion-content newsSubContent pressDropDownConent active' : 'dropdown-content accordion-content newsSubContent pressDropDownConent'

    if(TodaysDate !== undefined && content.length !== 1){ 
        
        return <div className='dropdown-content accordion-content newsSubContent pressDropDownConent' ref={accordionRef}>

                {
                 content.map( (newsData, newsIndex) => (
                   
                        dataTitle === "PRESS" ? <NewsAccordionItem typeName={newsData.__typename} accordionHooveringHandler={accordionHooveringHandler} dataTitle={dataTitle} title={newsData.title} sup={newsData.publication.name} outgoing={newsData.releaseLink} articleID={newsData.title} key={newsIndex}/> :
                         <NewsAccordionItem  dataTitle={dataTitle} typeName={newsData.__typename} accordionHooveringHandler={accordionHooveringHandler} title={newsData.title} sup={newsData.releaseType} outgoing={newsData.releaseLink} articleID={newsData.title} key={newsIndex}/> ))
                   
                }

        </div>
    }else{
        return <div className="dropdown-content accordion-content newsSubContent pressDropDownConent"></div>
    }

}
export default NewsAccordionContent;