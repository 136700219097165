import React, {useState} from 'react';
// import Link from "../Link.js";
import NavItemDropDown from './NavItemDropDown.js';
import {NavLink} from 'react-router-dom';

function NavItems({dropdown, className, mobileNavClickHandler = null}){
  
    const [isDropDownActive, setDropDownActive] = useState(false);
   
    const mobileDropDownClickHandler = function(){  
        setDropDownActive(isDropDownActive => !isDropDownActive);
    }
    if(mobileNavClickHandler == null){
    return <div className={className}>
                <NavLink to="/player" className="left-nav-span-link" ><span className="left-nav-span">Player</span></NavLink>
                <NavLink to="/news"  className="left-nav-span-link" ><span className="left-nav-span">News</span></NavLink>
                <div className={isDropDownActive ? "dropdown left-nav-span-link active" : "dropdown left-nav-span-link" }>
                    <button className="dropbtn left-nav-span" onClick={mobileDropDownClickHandler}>Broadcasts</button>
                    <NavItemDropDown dropdown={dropdown} ></NavItemDropDown>
                </div>
                <NavLink to="/connect"  className="left-nav-span-link" ><span className="left-nav-span">Connect</span></NavLink>    
            </div>
    }else{
        return <div className={className}>
                <NavLink to="/player" className="left-nav-span-link" onClick={ mobileNavClickHandler}><span className="left-nav-span">Player</span></NavLink>
                <NavLink to="/news"  className="left-nav-span-link" onClick={ mobileNavClickHandler}><span className="left-nav-span">News</span></NavLink>
                <div className={isDropDownActive ? "dropdown left-nav-span-link active" : "dropdown left-nav-span-link" }>
                    <button className="dropbtn left-nav-span" onClick={mobileDropDownClickHandler}>Broadcasts</button>
                    <NavItemDropDown dropdown={dropdown}  mobileNavClickHandler={ mobileNavClickHandler}></NavItemDropDown>
                </div>
                <NavLink to="/connect"  className="left-nav-span-link" onClick={ mobileNavClickHandler}><span className="left-nav-span">Connect</span></NavLink>    
            </div>
    }
   
}

export default NavItems;