import React from 'react';
import NewsAccordion from '../NewsAccordion/NewsAccordion.js';
import Subscribe from './Subscribe.js';
import './NewsNav.css';



function NewsNav({upcoming, releases, press, TodaysDate, accordionHooveringHandler, isHoovering, accordionState, accordionActiveHandler, intialLoadActive, simpleHooveringHandler}){
    //
    

    if(TodaysDate !== undefined ){
        let showUpcoming = upcoming.length <= 1 ? 0 : 1;
     return <div className="newsNavContainer" data-navparent="true">
                { showUpcoming ?
                     <NewsAccordion TodaysDate={TodaysDate} data={upcoming} title={"UPCOMING"} className={"dropdown accordion top"}  typename="Upcoming" intialLoadActive={intialLoadActive} accordionHooveringHandler={accordionHooveringHandler} isHoovering={isHoovering} accordionState accordionActiveHandler simpleHooveringHandler={simpleHooveringHandler}/> :
                     null
                }
                
                <NewsAccordion TodaysDate={TodaysDate} 
                               data={releases} 
                               title={"LATEST RELEASE"}
                               typename="Release"   
                               className={upcoming.length === 0 ? "dropdown accordion top" : "dropdown accordion" }  
                               accordionState={accordionState} 
                               accordionActiveHandler={accordionActiveHandler}
                               accordionHooveringHandler={accordionHooveringHandler}
                               isHoovering={isHoovering}
                               simpleHooveringHandler={simpleHooveringHandler}
                               intialLoadActive={intialLoadActive}
                />
                <NewsAccordion 
                               TodaysDate={TodaysDate}
                               data={press}
                               typename="Press" 
                               title={"PRESS"}  
                               className={"dropdown accordion top pressDropDown"}
                               accordionState={accordionState} 
                               accordionActiveHandler={accordionActiveHandler}
                               accordionHooveringHandler={accordionHooveringHandler}
                               isHoovering={isHoovering}
                               simpleHooveringHandler={simpleHooveringHandler}
                               intialLoadActive={intialLoadActive}
                               />
                {/* <Subscribe/> */}
           </div>
    }else{
        return <div className="newsNavContainer" data-navparent="true"></div>
    }
}
export default NewsNav;