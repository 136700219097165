import React from 'react';
import { Helmet } from 'react-helmet';
import ReactHlsPlayer from 'react-hls-player';
import '../Components/VideoBackgrounds/VideoBackgrounds.css';
import { motion } from "framer-motion";
import { gql, useQuery } from '@apollo/client';


const GET_HomePage = gql`{
  landingPageCollection(limit: 1) {
    items {
      name
      metaDescription
      metaTitle
      page {
        name
        pageType
        metaDescription
        navigationOptions
        layoutOptions
        metaTitle
        isPageVisibleToSearchEngines
      }
    }
  }
}
`;

function ScrollingText({updateNavVisablity}){
  React.useEffect(() => {
    updateNavVisablity( "Normal");
    }, [updateNavVisablity]);


  // console.log(data);
  const { loading, error, data } = useQuery(GET_HomePage);
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const HomeMeta = data.landingPageCollection.items[0];
  let VisableResponse = 'index, follow';

  const pageVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 1
      }
    }
  };
  const ExitVar = { opacity: 0, transition: { duration: 1.5, ease: [0.87, 0, 0.13, 1]} };

  return <div className="page-wrapper max-width Player-max-width">
              <Helmet>
                  <title>{HomeMeta.name}</title>
                  <meta name="description" content={HomeMeta.metaDescription} />
                  <meta name="title" content={HomeMeta.metaTitle} />
                  <meta name="robots" content={VisableResponse}></meta>
              </Helmet>
              <motion.div exit={ExitVar} initial="hidden" animate="visible" variants={pageVariants}>
                <div className="fullscreen-bg">	
                     <a href='https://lnk.to/peel_acidstar' className='LinkOut'>
                      <ReactHlsPlayer 
                          src={"https://static.peel.global/streams/backgrounds/scrollingText/scrollingText.m3u8"} 
                          autoPlay={true} 
                          playsInline={true} 
                          className="ActiveVideo backgroundVideoPlayer VideoScrollingText" 
                          controls={false} 
                          webkit-playsinline="true" 
                          muted={true} 
                          loop/>
                      </a>
                </div>
              </motion.div>
          </div>

}

export default ScrollingText;