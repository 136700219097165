import React from 'react';
function Article({article, title, isHoovering, isMobile, TodaysDate, intialLoadActive, mobileRef}) {

  let slug = title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').replace(/\s+/g, '-').toLowerCase().trim();
  let simpleSlug = article.__typename.toLowerCase().trim() + "-" + slug;
  const newsRef = React.useRef();
  isHoovering.isActive && isHoovering.currentID === simpleSlug && (newsRef.current.scrollIntoView({behavior: 'smooth'}))

  if (TodaysDate !== undefined) {

    function dateFormat(ArticleDate) {
      const d = new Date(ArticleDate);
      const year = d.getFullYear() // 2019
      const date = d.getDate() // 23
      const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
      const monthName = months[d.getMonth()]
      const formatted = monthName + ' ' + date + ', ' + year;
      return formatted;
    }
    let DateRow = dateFormat(article.publishDate);
    let currentPage = window.location.pathname;
    let activePath = currentPage.replace('/news/', '');

    let theClass = isHoovering.isActive && isHoovering.currentID === simpleSlug ? "articleContainer active" 
      : intialLoadActive.isActive && intialLoadActive.currentID === simpleSlug && !isHoovering.isActive   ? "articleContainer active" 
      : activePath === simpleSlug && !intialLoadActive.isActive && !isHoovering.isActive ? "articleContainer active" 
      : "articleContainer";

    return <div className={theClass} data-navlink={simpleSlug} ref={newsRef}>
      <div className="headlineRow">
        <h1 className="dropbtn accordion-title">"{article.title}"</h1>
        <sub className="time-column newsBarPublication">{article.publication.name}</sub>
      </div>
      <div className="dateRow">{DateRow}</div>
      <div className="articleExcerpt">{article.articleText}</div>
      <div className="readMore">
        <a href={article.linkToArticle} className="external">Read Full Article</a>
      </div>
    </div>

  } else {
    return <div className="articleContainer" data-navlink={simpleSlug}></div>
  }

}
export default Article;