import React from 'react';
import  "./VideoSlotMachine.css";
import { motion } from "framer-motion";

function VideoSlotMachine({ isSaveToPlay, updateNavVisablity, navigationOptions}){

  const streamURL = isSaveToPlay ? process.env.PUBLIC_URL + "/frames/peel-006-frame.html?unmute=1" : process.env.PUBLIC_URL + "/frames/peel-006-frame.html?unmute=0";
  const pageVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        delay: .5
      }
    }
  };
  const ExitVar = { opacity: 0, transition: { duration: 1.5, ease: [0.87, 0, 0.13, 1]} };
  React.useEffect(() => {
      console.log("navigationOptions"+navigationOptions);
  updateNavVisablity( navigationOptions);
  }, [navigationOptions, updateNavVisablity]);

   return <div className={"VideoSlotMachine"} >
            <motion.div exit={ExitVar} initial="hidden" animate="visible" variants={pageVariants}>
                    <iframe
                    src={streamURL}
                    width="100%"
                    height="700"
                    title="broadcast006"
                    className="iFrameFull"
                    frameBorder="0"
                    marginHeight="0"
                    marginWidth="0"
                    allow="autoplay;"
                        />
            </motion.div>

    </div>

   
}

  
export default VideoSlotMachine;