import { gql } from '@apollo/client';

    const GET_NEWSDATA = gql`query GetNewsData($releaseDate: DateTime!) {
      pageCollection(where: {slug: "news"}) {
        items {
          slug
          metaTitle
          metaDescription
          isPageVisibleToSearchEngines
          name
        }
      }
      releasesCollection(where: {releaseDate_lt: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title
          releaseDate
          releaseImage: releaseArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug
          releaseType
          releaseLink: mediaUrl
          text
          excludeFromReleasePage
          __typename
        }
      }
      UpcomingRelease: releasesCollection(where: {releaseDate_gte: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title
          releaseDate
          releaseImage: releaseArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug
          releaseType
          releaseLink: mediaUrl
          text
          excludeFromReleasePage
          __typename
        }
      }
      albumCollection(where: {releaseDate_lt: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: name
          releaseDate
          releaseImage: artwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug: name
          releaseType
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      UpcomingAlbum: albumCollection(where: {releaseDate_gte: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: name
          releaseDate
          releaseImage: artwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug: name
          releaseType
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      broadcastCollection(where: {releaseDate_lt: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: name
          releaseDate
          releaseImage: releaseArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug
          releaseType
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      UpcomingBroadcast: broadcastCollection(where: {releaseDate_gte: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: name
          releaseDate
          releaseImage: releaseArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          additionalData
          releaseLayout
          slug
          releaseType
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      albumSongsCollection(where: {releaseDate_lt: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: songTitle
          releaseDate
          releaseImage: singleArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          metaTitle: songTitle
          releaseLayout
          slug: songTitle
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      UpcomingSongs: albumSongsCollection(where: {releaseDate_gte: $releaseDate, excludeFromReleasePage: false}) {
        items {
          title: songTitle
          releaseDate
          releaseImage: singleArtwork {
            title
            description
            contentType
            fileName
            size
            url
            width
            height
          }
          metaTitle: songTitle
          releaseLayout
          slug: songTitle
          releaseLink
          text: releaseText
          excludeFromReleasePage
          __typename
        }
      }
      pressCollection(where: {releaseDate_lt: $releaseDate}) {
        items {
          title
          releaseDate
          shortTitle
          slug
          publication {
            name
          }
          publishDate
          articleText
          linkToArticle
          archiveLinkToArticle
          __typename
        }
      }
      UpcomingPress: pressCollection(where: {releaseDate_gte: $releaseDate}) {
        items {
          title
          releaseDate
          shortTitle
          slug
          publication {
            name
          }
          publishDate
          articleText
          linkToArticle
          archiveLinkToArticle
          __typename
        }
      }
    }`;

export { GET_NEWSDATA };