import React,{useState, useEffect, useRef} from 'react';
import './App.css';
import Navigation from './Components/Navigation/Navigation.js';
import PlayerPage from './Pages/PlayerPage.js';
import ScrollingText from './Pages/ScrollingText.js';
import News from './Pages/News.js';
import Broadcast from './Pages/Broadcast.js';
import ConnectPage from './Pages/ConnectPage.js';
import usePrevLocation from './Components/Hooks/usePrevLocation.js';

import {
  Switch,
  Route,
  useLocation,
  useHistory
} from 'react-router-dom';
import { AnimatePresence } from "framer-motion";

function App() {
  
//THIS IS HOW YOU USE IT

  const [isSaveToPlay, setToSafeToPlay] = useState(false);
  const [isNavVisable, setNavtoInVisable] = useState(true);
  const [hasAPageLoaded, sethasAPageLoaded] = useState(false);
  const [hideLogo, setHideLogo] = useState(false);

  let history = useHistory();
  console.log(history);
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  console.log("location: "+location.pathname+ "   -  prevLocation:"+prevLocation.pathname);
  
  // const hideLogo = prevLocation.pathname === '/' ? 1 : 0;
  const now = useRef(new Date());
  
  useEffect(()=>{
    prevLocation.pathname !== location.pathname &&   sethasAPageLoaded(true);
    },[prevLocation.pathname, location.pathname])

  useEffect(()=>{
    prevLocation.pathname !== location.pathname &&  setToSafeToPlay(true);
    },[prevLocation.pathname, location.pathname])

    useEffect(()=>{
      // if(prevLocation.pathname === '/' && prevLocation.pathname !== location.pathname ){
      //   setHideLogo(1);
      // }else
       if(location.pathname === '/'){
        setHideLogo(1);
      }
      else{
        setHideLogo(0);
      }

      },[prevLocation.pathname, location.pathname])

  const saveToPlayHandler = function(){  
    setToSafeToPlay(true);
    sethasAPageLoaded(true);    
  }

  const updateNavVisablity = function(Value){  
    Value === "FadeOut" ?  setNavtoInVisable(false) : setNavtoInVisable(true) ;  
  }
  return (
    <div className="parent">
      <Navigation isNavVisable={isNavVisable} hideLogo={hideLogo}></Navigation>
      <AnimatePresence exitBeforeEnter>  
        <Switch >
        <Route exact path="/" >
            <ScrollingText updateNavVisablity={updateNavVisablity}/> 
          </Route>
          <Route exact path="/player" >
            <PlayerPage updateNavVisablity={updateNavVisablity}/> 
          </Route>
          <Route path="/news">
              <News now={now} updateNavVisablity={updateNavVisablity} isSubPage={false}/>
          </Route>
          <Route path="/news/:id">
              <News now={now} updateNavVisablity={updateNavVisablity} isSubPage={true}/>
          </Route>
          <Route path="/broadcast:id">
              <Broadcast  saveToPlayHandler={saveToPlayHandler} isSaveToPlay={isSaveToPlay} hasAPageLoaded={hasAPageLoaded} updateNavVisablity={updateNavVisablity}/>
          </Route>  
          <Route path="/connect">
              <ConnectPage updateNavVisablity={updateNavVisablity}/>
          </Route> 
        </Switch>
      </AnimatePresence>
    </div>
   
  );
}

export default App;