import React from 'react';
import './Logo.css';
import Link from "../Link";

function Logo({newClass}){
    return <Link className={"home-link "+ newClass}
               href="/" >
                <div className="icon icon-peel"></div>
            </Link>
}

export default Logo;