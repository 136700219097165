import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import Accordion from '../Components/Accordion/Accordion.js';


const Get_Connect = gql`{
    connectCollection{
           items{
                 contactText
                 emailText
                 email
                 instagramUrl
                 twitterUrl,
                 tikTokUrl
                 }
           }
    pageCollection(where: {slug: "connect"}){
      items{
            name
            metaDescription
            metaTitle
            isPageVisibleToSearchEngines
      }
    }
  }
 `;

function ConnectPage({updateNavVisablity}){
    const [accordionState, setAccordionState] = useState({ isActive: false, currentID: '', previousID: ''});
    const accordionActiveHandler = function(currentID){    
        let previousCurrent = accordionState.currentID;
        if(currentID === accordionState.currentID){
            // click twice toogle on same.
             setAccordionState({...accordionState, isActive: !accordionState.isActive, currentID: '', previousID: currentID});
         }else if(accordionState.currentID === '' && !accordionState.isActive){
            // first state
            setAccordionState({ ...accordionState,isActive: true, currentID: currentID,previousID: ''});
        }else{
            // deactivate previous
            setAccordionState({...accordionState,isActive: true, currentID: currentID, previousID: previousCurrent });
        }                                             
    }
    React.useEffect(() => {
    updateNavVisablity( "Normal");
    }, [updateNavVisablity]);

    const { loading, error, data } = useQuery(Get_Connect);
    if (loading) return '';
    if (error) return `Error! ${error.message}`;
    const pageData = data.pageCollection.items[0];

    const peelData = data.connectCollection.items.filter((item) => item.contactText === "Peel");
    const peelArray = [{ "label": "Instagram", "textWrap": 1 ,"link" : peelData[0].instagramUrl},
                        { "label": "Twitter", "textWrap": 1 ,"link" : peelData[0].twitterUrl},
                        { "label": "TikTok", "textWrap": 1 ,"link" : peelData[0].tikTokUrl}];
                        
    const management = data.connectCollection.items.filter((item) => item.contactText !== "Peel");
    
    function reformatManagementData(x) {
    
        let json = {}

        const label = "label";
        json[label] = x.contactText;
        const textWrap = "textWrap";
        json[textWrap] = 0;
        const link = "link";
        json[link] = "mailto:"+x.email;      
        const linkLabel = "linkLabel";
        json[linkLabel] = x.email;

        return json
    
    }

    const topConnectArray =  management.map(attr => reformatManagementData(attr));

    let isPageVisibleToSearchEngines = pageData.isPageVisibleToSearchEngines;
    let VisableResponse = (isPageVisibleToSearchEngines == null || !isPageVisibleToSearchEngines) ? 'noindex, nofollow' : 'index, follow';

    return <div className={"page-wrapper max-width Player-max-width"}>
                <div className="contactsPageContainer">
                    <Helmet>
                        <title>{pageData.name}</title>
                        <meta name="description" content={pageData.metaDescription} />
                        <meta name="title" content={pageData.metaTitle} />
                        <meta name="robots" content={VisableResponse}></meta>
                    </Helmet>
                   
                        <div className="contactsNavContainer" data-navparent="true">
                       
                            <Accordion items={topConnectArray} title={"Contact"} additionalClass={"top"} accordionHandler={accordionActiveHandler} state={"Contact" !== accordionState.previousID && accordionState.currentID !== '' && "Contact" === accordionState.currentID ? 1 : 0}></Accordion>
                            <Accordion items={peelArray} title={"Social"}  additionalClass={""} accordionHandler={accordionActiveHandler} state={"Social" !== accordionState.previousID && accordionState.currentID !== '' &&  "Social" === accordionState.currentID? 1 : 0}></Accordion>
                          
                        </div>
                   
                </div>
           </div>
}
export default ConnectPage;