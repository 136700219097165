import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import './VideoBackgrounds.css';

export default function VideoBackgrounds({videoPath, music}){
  
    return <div className="fullscreen-bg">	

            {/* { music.items.map((video, videoIndex) => ( 
               
                    <ReactHlsPlayer src={"https://static.peel.global/streams/backgrounds/"+video.nameLowerCase+"/720p.m3u8"} 
                                key={videoIndex+"video"} 
                                autoPlay={video.nameLowerCase === videoPath ? true : false} 
                                controls={false} 
                                webkit-playsinline="true" 
                                loop muted playsInline 
                                className={ video.nameLowerCase === videoPath ? "ActiveVideo backgroundVideoPlayer" : "backgroundVideoPlayer"}
                              
                                />

              
                               
                )
            )
            
            } */}
            { videoPath ?  <ReactHlsPlayer src={"https://static.peel.global/streams/backgrounds/"+videoPath+"/720p.m3u8"} autoPlay={true} playsInline={true} className="ActiveVideo backgroundVideoPlayer" controls={false} webkit-playsinline="true" muted={true} loop/> : null }
            {/* <ReactHlsPlayer src={"https://static.peel.global/streams/backgrounds/"+videoPath+"/720p.m3u8"} autoPlay={true} playsInline={true} className="ActiveVideo backgroundVideoPlayer" controls={false} webkit-playsinline="true" muted={true} loop/> */}
            </div>
}