import React, { useState } from 'react';
import MusicPlayer from '../MusicPlayer/MusicPlayer.js';
import VideoBackgrounds from '../VideoBackgrounds/VideoBackgrounds.js';

function VideoMusicPlayer(props) {


    const [isPlayerActive, setisPlayerActive] = useState(false);
    const [videoPath, setvideoPath] = useState("");
    // const startPlayingHandler = (title) => {

    //     let theVideo    = document.getElementsByTagName(title+"-audio");
    //     let playPause   = document.getElementById(title+"-play-btn");
    //     if(theVideo[0].paused){ theVideo[0].play();  playPause.setAttribute('d', "M0 0h6v24H0zM12 0h6v24h-6z"); }

    // }
    const songActiveToogleHandler = function (e, title) {
        setisPlayerActive(isPlayerActive => !isPlayerActive);
        setvideoPath(title);
        // console.log('title:'+title);
        // startPlayingHandler(title);
    }


    const Songs = props.songData;
    return <div className="VideoMusicPlayer">

        <MusicPlayer music={Songs}
            onSongActivation={songActiveToogleHandler}
            activationState={isPlayerActive}
            videoPath={videoPath}
        />
        <VideoBackgrounds videoPath={videoPath} music={Songs} />
        {/* {videoPath ?  : null} */}
    </div>
}

export default VideoMusicPlayer;