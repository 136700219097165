

import { useState, useEffect } from "react";

const useDeviceCheck = () => {

const [width, setWidth] = useState(window.innerWidth);
// const [height, setheight] = useState(window.innerHeight);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    // setheight(window.innerHeight);
}

useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
//height <= 481 || 
let isMobile = width <= 768 ? 1 : 0;
    return [isMobile];
}
export default useDeviceCheck;