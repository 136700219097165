// import React, { useState } from 'react';
import React from 'react';
import  './Accordion.css';
import AccordionTitle from './AccordionTitle.js';
import AccordionContent from './AccordionContent.js';

function Accordion(props){
    
    let currentID = props.title.replace(/[^A-Z0-9]/ig, "_");
    let accordionActiveHandler = props.accordionHandler;
    let areYouActive  = props.state;            
    let parentAccord = (props.additionalClass === ''  ||  props.additionalClass === null) ? "dropdown accordion" : "dropdown accordion "+ props.additionalClass;
    
    return <div className={parentAccord} onClick={(event) => {  accordionActiveHandler(currentID) }}>
            <AccordionTitle  title={props.title} />
            <div className={areYouActive ? "dropdown-content accordion-content active" : "dropdown-content accordion-content"}>
                {props.items.map( (accordion, accordionIndex) => ( <AccordionContent  accordion={accordion} key={accordionIndex}/>)) }
            </div>
     </div>
   
}
export default Accordion;