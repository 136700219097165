
import React from 'react';
function ReleaseMobile({ release, TodaysDate, isHoovering}){
    const articleRef = React.useRef();
    if(TodaysDate !== undefined ){
  
       
        let slug = release.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').replace(/\s+/g, '-').replace(/\s+/g, '-').toLowerCase().trim();
        let simpleSlug = release.__typename.toLowerCase().trim()+"-"+slug;
        let theClass = isHoovering.isActive && isHoovering.currentID === simpleSlug
        ? "articleContainer articleReleasesContainer active"
        : "articleContainer articleReleasesContainer";
        
        isHoovering.isActive && isHoovering.currentID === simpleSlug && articleRef.current.scrollIntoView(true)
      


        return <div className={theClass} date-layout={release.releaseLayout} data-navlink={simpleSlug} ref={articleRef} >
                <a href={release.releaseLink}  className="external blink">
                    {release.text}
                </a>
            </div>
    
    }else{
        return null;
    }
}
export default ReleaseMobile;