import React from 'react';
import BroadCastListing from './BroadCastListing.js';
import VideoBox from './VideoBox.js';
import VideoThumbnail from './VideoThumbnail.js';
import './VideoPlayList.css';

import useDeviceCheck from '../Hooks/useDeviceCheck';

import { motion } from "framer-motion";

function VideoPlayList({data, saveToPlayHandler, isSaveToPlay, hasAPageLoaded, navigationOptions, updateNavVisablity}){
    let [isMobile] = useDeviceCheck();
    const [startPlaying, makeItStartPlaying] = React.useState(false);
    const [VideBoxHeight, SetVideoBox] = React.useState(0);
    // const [isMobileListingActive, setMobileListingActive] = React.useState(false);
    // const [eventType, setEventType] = React.useState<string>("");

    // const handleGesture = (event: HammerInput) => setEventType(event.type);

    let broadcast = data.broadcastCollection.items[0];
    let image     = broadcast.thumbnail;
    React.useEffect(() => {
        console.log("navigationOptions"+navigationOptions);
    updateNavVisablity( navigationOptions);
  
    }, [navigationOptions, updateNavVisablity]);
   
    React.useEffect(() => {
     isSaveToPlay && hasAPageLoaded && makeItStartPlaying(true);
  }, []);
    
    // function MobileListingActiveHandler() {
    //   setMobileListingActive(isMobileListingActive => !isMobileListingActive);
    // }
    function startPlayingHandler() {
      // makeItStartPlaying(true);
      let theVideo = document.getElementsByTagName('video');
      let playPause = document.getElementById('playPause');
      if(theVideo[0].paused){ theVideo[0].play();  playPause.setAttribute('d', "M0 0h6v24H0zM12 0h6v24h-6z"); }
    }
  
  //   const onUpdateVideoTime = function(TimeStamp){  
  //     saveToPlayHandler();
    // let videoBoxHeight = !isSaveToPlay && !hasAPageLoaded && document.getElementById('broadcast001') !== null ?  document.getElementById('broadcast001').height : 'auto';
         
    function updateHeightHandler(Height) {
      // makeItStartPlaying(true);
      // videoBoxNewHeight = Height;
      SetVideoBox(Height);
    }
   
  // }
    const imageVariants = {
        hidden: {
          opacity: 0
        },
        visible: {
          opacity: 1,
          transition: {
            delay: .1
          }
        }
      };
    const listingVariants = {
        hidden: {
          opacity: 0
        },
        visible: {
          opacity: 1,
          transition: {
            delay: 1.5
          }
        }
      };

     let videoBoxNewHeight =  VideBoxHeight !== 0 ? parseInt(VideBoxHeight)+60 : 'auto';
    return <div className={"VideoPlayList"} style={{height: videoBoxNewHeight }}>
                <motion.div initial="hidden" animate="visible" variants={imageVariants} className={"holdTimeDown"} style={{height: videoBoxNewHeight }}>
                    <div className="video-box-container">
                    {/* { !isSaveToPlay && !hasAPageLoaded && <VideoThumbnail image={image} id={broadcast.slug} onClick={saveToPlayHandler} className={"videothumbnail notplayedyet"} /> } */}
                    <VideoThumbnail image={image} id={broadcast.slug} onClick={() => {saveToPlayHandler(); startPlayingHandler();}} updateHeightHandler={updateHeightHandler} className={!isSaveToPlay && !hasAPageLoaded ? "videothumbnail notplayedyet" :  "videothumbnail fadeOut"} /> 
                    <VideoBox broadcast={broadcast} isSaveToPlay={isSaveToPlay} videoBoxNewHeight={videoBoxNewHeight} startPlaying={startPlaying} hasAPageLoaded={hasAPageLoaded} isMobile={isMobile}/>
                    {/* { !isSaveToPlay && !hasAPageLoaded ? <VideoThumbnail image={image} id={broadcast.slug} onClick={saveToPlayHandler}/> :
                      <motion.div initial="hidden" animate="visible" variants={imageVariants}> <VideoBox broadcast={broadcast} fullScreenHandler={fullScreenHandler} isSaveToPlay={isSaveToPlay} isFullScreen={isFullScreen} isMobile={isMobile}/> </motion.div>
                    } */}

                    </div>
                    </motion.div>
                    <motion.div initial="hidden" animate="visible" variants={listingVariants}>
                    <BroadCastListing data={data} saveToPlayHandler={saveToPlayHandler} /> 
                   {/* { isMobile && isFullScreen ? <div class={isMobileListingActive? "swipableTimeCompent active" : "swipableTimeCompent"} onClick={MobileListingActiveHandler}> <BroadCastListing data={data} saveToPlayHandler={saveToPlayHandler} /> </div> : <BroadCastListing data={data} saveToPlayHandler={saveToPlayHandler} /> }  */}
                        
                    </motion.div>
            </div>
}
export default VideoPlayList;
