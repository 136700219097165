import { useQuery } from '@apollo/client';
import { GET_NEWSDATA }  from './useNewsQuery';

const useFetchNews = (today) => { 
  
    // const { loading, data } = useQuery(QUERY, { skip })
    const date = today == null ? new Date().toISOString() : today;
    const  { loading, error, data } = useQuery( GET_NEWSDATA, { 
            variables: { "releaseDate": date }, 
            // fetchPolicy: "no-cache",
            // skip
        });
    // React.useEffect(() => {
    // // check whether data exists
    // if (!loading && !!data) {
    //     setSkip(true)
    // }
    // }, [data, loading])
    

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    // const jsonSort = (Json) =>{
    //     let sorted = Json.sort((a, b) => parseFloat(a.Json.releaseDate ) - parseFloat(b.Json.releaseDate));
    //     return sorted;
    // }

    function jsonSort(a, b) {
        return parseFloat(a.releaseDate) - parseFloat(b.releaseDate);
    }
    // Page Data
    const releasesCollection    = data.releasesCollection.items;
    const albumCollection       = data.albumCollection.items;
    const albumSongsCollection  = data.albumSongsCollection.items;
    const Songs                 = albumSongsCollection.map(v => ({...v, releaseType: "AIFF"}));
    const broadcastCollection   = data.broadcastCollection.items;
    const Press                 = data.pressCollection.items;


    const UpcomingRelease       = data.UpcomingRelease.items;
    const UpcomingAlbum         = data.UpcomingAlbum.items;
    const UpcomingSongsItems    = data.UpcomingSongs.items;
    const UpcomingSongs         = UpcomingSongsItems.length !== 0 && UpcomingSongsItems.map(v => ({...v, releaseType: "AIFF"}));
    const UpcomingBroadcast     = data.UpcomingBroadcast.items;

    const  News = data.pageCollection.items[0];
    const releaseMerged = releasesCollection.concat(albumCollection).concat(Songs).concat(broadcastCollection);

    const Releases = releaseMerged.sort(
        function(a, b){
           const date1 = new Date(b.releaseDate);
           const date2 = new Date(a.releaseDate);
            return date1 - date2 ;
        }
        );
   

    const upcomingMerged = UpcomingRelease.concat(UpcomingAlbum).concat(UpcomingSongs).concat(UpcomingBroadcast);
    const Upcoming = upcomingMerged.sort(jsonSort);

    return[
        News,
        Press,
        Releases,
        Upcoming,
        data
    ]

}
export default useFetchNews;