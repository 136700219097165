import React  from 'react'
// import { useState } from "react";
import './MusicPlayerSong.css';
import MusicPlayerControls from './MusicPlayerControls.js';
import ScrollingText from './MusicPlayerScrollingText.js';

function MusicPlayerSong(props){

	let songSingle = props.songs;
	let songActiveToogleHandler = props.onSongActivation;
	let isPlayerActive = (props.videoPath === songSingle.nameLowerCase) ? 1 :
						 (props.activationState) ? 1 : 0;
	let currentPlayer = (props.videoPath === songSingle.nameLowerCase) ? 1 : 0;
	

	return (<div id={songSingle.playerShortTitle+'-cad'} className={"scrolling-text song-"+songSingle.playerShortTitle} >
				{ isPlayerActive && currentPlayer ? <MusicPlayerControls song={songSingle} startPlaying={true} ></MusicPlayerControls> : 
				 <ScrollingText song={songSingle}  onSongActivation={songActiveToogleHandler} /> 
				}
				
			</div>

			);
	
}

export default MusicPlayerSong;