
import React from 'react';
function MobileContainer({ TodaysDate, releaseContent, articlesContent, mobileRef}){

    if(TodaysDate !== undefined ){

        return <div className="newsContainerArticles" ref={mobileRef}>
               {releaseContent}
               {articlesContent}
            </div>
    
    }else{
        return null;
    }
}
export default MobileContainer;