import React, {useLayoutEffect} from 'react';
import NewsAccordionContent from './NewsAccordionContent';


function NewsAccordion({TodaysDate, data, title, typename, intialLoadActive, className, accordionState, accordionActiveHandler,  accordionHooveringHandler, isHoovering, simpleHooveringHandler}){
    // ng
  
    // const dateToEpoch  = (publishedDateString) =>{
    //     let publishedDate = new Date(publishedDateString);
    //     publishedDate = publishedDate.getTime();
    //     return publishedDate;
    //   }
      
    // const hasPublished  = (publishedDateString) =>{
    //     const currentTime = Date.now();
    //     let publishedDate = dateToEpoch(publishedDateString);
    //     let timeRemaining = currentTime - publishedDate;
    //     let  isItPublished = timeRemaining > 0 ? 0: 1;
    //     return isItPublished;currentID: '', previousID: currentID
    // }
    const accordionHolderRef = React.createRef();

    useLayoutEffect(() => {
        // Add a custom perf marker (shows up in chrome's performance trace)

      if(accordionHolderRef !== null){ 
            if (isHoovering.isActive && isHoovering.currentID === title) {
              accordionHolderRef.current.classList.add("hoveringChildren");
            } else {
              accordionHolderRef.current.classList.remove("hoveringChildren");
            }
        }

      }, [accordionHolderRef, isHoovering, title]);
    
    if(TodaysDate !== undefined){  
      let ClickID = title.replace(/\s+/g, '-').toLowerCase().trim();
     let simpleSlug = typename.toLowerCase().trim()+"-"+ClickID
     console.log(isHoovering.isActive +'&&'+ isHoovering.currentID +' ===' + title + 'intialLoadActive.currentID: '+ intialLoadActive.currentID.startsWith(typename.toLowerCase()));
     
     

    return <div className={className}   ref={accordionHolderRef}                   
                       
                          onMouseEnter={() => { simpleHooveringHandler(true, title); }} 
                          onMouseLeave={() => { simpleHooveringHandler(false, '', title); }}>
              <button className="dropbtn accordion-title"  onClick={() => { accordionActiveHandler(simpleSlug); }} >{title}</button>
              
              <NewsAccordionContent content={data}   intialLoadActive={intialLoadActive} dataTitle={title} simpleSlug={ simpleSlug} state={(ClickID !== accordionState.previousID && accordionState.currentID !== '' && simpleSlug  === accordionState.currentID) || intialLoadActive.currentID.startsWith(typename.toLowerCase())? 1 : 0} TodaysDate={TodaysDate} accordionHooveringHandler={accordionHooveringHandler}/>
            </div>
    }else{
      return <div className={className} onClick={accordionActiveHandler}></div>
    }

}
export default NewsAccordion;