import React from 'react';
import ListingItem from './BroadCastListingItem.js';
function BroadCastListing({ data, saveToPlayHandler }){
 
   
    const broadcastListing = data.broadcastCollection.items[0];
    const songCollection = broadcastListing.songTitlesCollection.items;
    console.log(songCollection);
    const rowLen = songCollection.length;
    
    const onUpdateVideoTime = function(TimeStamp){  
        saveToPlayHandler();
        let theVideo = document.getElementsByTagName('video');
        theVideo[0].currentTime = TimeStamp;
        let playPause = document.getElementById('playPause');
        if(theVideo[0].paused){ theVideo[0].play();  playPause.setAttribute('d', "M0 0h6v24H0zM12 0h6v24h-6z");}
        console.log(TimeStamp);

    }

    //
    return <div className="video-credit-container">
                <span className="credit-title uppercase">BroadCast {broadcastListing.broadcastNumber} Listing</span>
                <div className="timecode-credits">
                    { songCollection.map( (broadcastListItem, i) => ( 
                       
                         <ListingItem listing={broadcastListItem} key={i} index={i} length={rowLen} clickHandler={onUpdateVideoTime}></ListingItem>
                    )) }
                </div>
               
        </div>
}
export default BroadCastListing;