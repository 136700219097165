import React from 'react';
function SpotifyAd(props){
    if(props.song.spotifyURl !== null){
        let songFinished = props.showOrHide;
        return <div className={ songFinished ?  "spotifyAd fadeItIn"  : "spotifyAd" } key={props.index+'-spot'} >
            <div className="play-title">
                <a href={props.song.spotifyURl}>
                <span className="linebreak">Listen in</span>
                <span className="linebreak"> Spotify</span>
                </a>
            </div>
        </div>

    }else{
        return null;
    }
    
}
export default SpotifyAd;
