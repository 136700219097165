import React, { useRef } from 'react';
// import { useState } from "react";
// import ProgressBar from './ProgressBar.js';
import ReactHlsPlayer from 'react-hls-player';
import './MusicPlayerControls.css';
import useVideoPlayer from "../Hooks/useVideoPlayer";
import SpotifyAd from './SpotifyAd.js';
function MusicPlayerControls(props){
    let song = props.song;
    let shortTile = props.song.playerShortTitle;
    let idTitle = props.song.nameLowerCase;
    let streamURL = (props.song.spotifyURl !== null) ? "https://static.peel.global/music/previews/"+props.song.nameLowerCase+"/"+props.song.nameLowerCase+".m3u8": 
                                                       "https://static.peel.global/music/streams/"+props.song.nameLowerCase+"/"+props.song.nameLowerCase+".m3u8";
    let  isSaveToPlay = true;
    let songFinishedHandler = props.onSongFinishedHandler;
    const videoElement = useRef(null);
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        handleVideoProgress,
        handleCurrentValueFormat,
        seekhandler,
    } = useVideoPlayer(videoElement, isSaveToPlay, props.startPlaying);
    
    // function seekhandler(event, id) {
    //     // let progressBar = document.getElementById(id);
	// 	var percent = event.nativeEvent.offsetX /  event.target.offsetWidth;
	// 	let newCurrentTime = percent * 100;
	// 	// e.target.value = percent / 100;
    //     console.log(newCurrentTime);
    //     handleSeekVideoProgress(parseFloat(newCurrentTime) );
	// }
   

    return  <div className={"play-title audio-player"}  data-idpre={song.playerShortTitle} >
                <div className={ playerState.progress === 100 ? "playerontrols col-100 row hide-player" : "playerontrols col-100 row"} key={props.index+'-playerContro'}>
                    <div id={idTitle+"-play-btn"} className={playerState.isPlaying ? "col-25 icon-play" :  "col-25 icon-pause" } onClick={() => {  togglePlay();}} ></div>
                    { playerState.progress === 100 && songFinishedHandler}
                    <div className="audio-wrapper col-50" id={shortTile+"-player-container"}>
                        <ReactHlsPlayer
                            id={idTitle+"-audio"}
                            playerRef={videoElement}
                            onTimeUpdate={handleOnTimeUpdate}
                            src={streamURL}
                            playsInline
                            autoPlay={true}
                        />
                        <div className={"play-title song-"+shortTile}>
                            <span>{props.song.songTitle}</span>
                        </div>
                    </div>
                    <small className={"stimebox col-25 start-time"}>{  handleCurrentValueFormat(playerState.currentTimeFormated)  }</small>
                </div>
                <div id={song.playerShortTitle+"-seekObjContainer"} className={ playerState.progress === 100 ? "col-100 seekObjContainer hide-player" : "col-100 seekObjContainer"} >
                    <div className="player-controls scrubber">
                        <progress id={"seekObj-"+song.playerShortTitle} value={ playerState.progress !== 'NaN' ? parseInt(playerState.progress) : 0}
                        // {playerState.isPlaying && playerState.progress !== 'NaN' ? parseInt(playerState.progress) : 0}
                         max="100" className="seekObj"  onClick={(event) => {  seekhandler(event)}} onChange={(e) => handleVideoProgress(e)}></progress> 
                    </div>
                </div>
                <SpotifyAd song={song}  showOrHide={playerState.progress === 100 ? true : false } />
         </div>
}

export default MusicPlayerControls;

