import React, {useState, useEffect, useRef} from 'react';
// import {fetch} from 'whatwg-fetch'
const eo = require('email-octopus');

function Subscribe(){
    const subscribeContainer = useRef();
    const emailtoSubscribe = useRef();
    const [isActive, setisActive] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const octoKey = process.env.REACT_APP_EMAILOCTOPUS;
    const LISTID  = process.env.REACT_APP_EMAILOCTOPUSLIST;
    const emailOctopus = new eo.EmailOctopus(octoKey);

    const subscribeHandler = function(e){
        e.preventDefault();

        console.log('submittbutton');
        const email = emailtoSubscribe.current.value;

        var listId = LISTID
        var options = {
            email_address: email,
            first_name: '',
            last_name: ''
        };
        
        emailOctopus.lists.contacts.create(listId, options).then(function() {
            console.log('contact added:' + email);
        });

    //     
    //     console.log(email);
    //     let url = "https://emailoctopus.com/api/1.5/lists/"+LISTID+"/contacts"
    //   fetch(url, {
    //     method: 'POST',
    //     credentials: 'include',
    //     crossDomain:true,
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': 
    //     },
    //     body: JSON.stringify({
    //         "api_key": octoKey,
    //         "email_address": email,
    //         "fields": {
    //             "FirstName": "",
    //             "LastName": ""
    //         },
    //         "status":"SUBSCRIBED"
    //     })
    //   }).then(response => response.json())
    //     .catch(err => {
    //         console.log(err) // Handle errors
    //     });

    setTimeout(function (){
        setIsSubmitted(true);
    }, 5000);

    
    }
  

 

    const subscribeToogleHandler = function(e){
        if(subscribeContainer.current.contains(e.target) && isActive === true) {
            // inside click
          console.log('subscribeToogleHandler: click inside ' + e.target.className);

          }else if(subscribeContainer.current.contains(e.target) && isActive === false){
            setisActive(true)
            console.log('subscribeToogleHandler: notActivated ' + e.target.className);
          
        }else{
            setisActive(false)
            console.log('subscribeToogleHandler: Else ' + e.target.className);
        }
        
        setIsSubmitted(false);

    }

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", subscribeToogleHandler);
        // return function to be called when unmounted
        return () => {
          document.removeEventListener("mousedown", subscribeToogleHandler);
        };
      }, []);

      

    return 	<div className={isActive ? "email-octopus-form-wrapper subscribeContainer active" :
                         isSubmitted ? "email-octopus-form-wrapper subscribeContainer submit" : "email-octopus-form-wrapper subscribeContainer"} ref={subscribeContainer}>
                
                <p className="email-octopus-error-message"></p>

                <form 
                    // method="post" 
                    // action={"https://emailoctopus.com/lists/c9cb91eb-4339-11ec-96e5-06b4694bee2a/members/embedded/1.3/add"} 
                    className="email-octopus-form bound" 
                    // data-sitekey="a77f376c-2b11-4f32-8840-ad181df0bf61"
                    data-message-success="YOU ARE NOW SUBSCRIBED!" data-message-missing-email-address="Your email address is required." data-message-invalid-email-address="Your email address looks incorrect, please try again."
                    data-message-bot-submission-error="This doesn't look like a human submission." data-message-consent-required="Please check the checkbox to indicate your consent."
                    data-message-invalid-parameters-error="This form has missing or invalid fields." data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later.">

                    <div className="email-octopus-form-row">
                        {isSubmitted ? <p className="email-octopus-success-message">YOU ARE NOW SUBSCRIBED!</p> : <input id="field_0" name="field_0" type="email" placeholder="ENTER EMAIL" ref={emailtoSubscribe}/>}
                    </div>
                    <div className="email-octopus-form-row-hp" aria-hidden="true">
                        <input type="text" name="hpc9cb91eb-4339-11ec-96e5-06b4694bee2a" tabIndex="-1" autoComplete="nope"/>
                    </div>

                    <div className="email-octopus-form-row-subscribe accordion-content-row">
                        <span className="checkoutIcon icon-peel-arrow-glyph subscribeGlyph"></span>
                        {isActive ? <button className="subButton dropbtn accordion-title" onClick={(e) => {subscribeHandler(e)}}  > SUBSCRIBE</button> : <span className="subButton dropbtn accordion-title" onClick={(e) => subscribeToogleHandler(e)}  > SUBSCRIBE</span>}
                         
                    </div>
                </form>

            </div>

}
export default Subscribe;