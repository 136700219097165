import React from 'react';


function AccordionContent(props){
 
    let linkWraped = (props.accordion.textWrap) ? (<a href={props.accordion.link} className="external underline socialLinks">{props.accordion.label}</a>):
                                        (<span className="Label"> {props.accordion.label}, <a href={props.accordion.link} className="external underline">{props.accordion.linkLabel}</a></span>)
	return <div className="accordion-content-row row">
       <span className="checkoutIcon icon-peel-arrow-glyph"></span>
       {linkWraped}
    </div>
}
export default AccordionContent;