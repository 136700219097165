import { useState, useEffect, useLayoutEffect } from "react";

const useVideoPlayer = (videoElement, auto = true, startPlaying = true) => {
  
  const [playerState, setPlayerState] = useState({
    isPlaying: auto,
    progress: 0,
    currentTimeFormated: 0,
    speed: 1,
    isMuted: false
  });

  const [volumeDragState, setVolumeDragState] = useState({
    activeDrags: 0,
    deltaPosition: {
      x: 100
    }
  });
  const [isVolumeActive, setVolumeActive] = useState(false);

  const volumeHandler = () => {
    setVolumeActive(isVolumeActive => !isVolumeActive);
  }

  
  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying,
    });
    
  };

  useEffect(() => {
                  // true       
    var isPlaying = videoElement.current.currentTime > 0 && !videoElement.current.paused && !videoElement.current.ended  && videoElement.current.readyState > videoElement.current.HAVE_CURRENT_DATA;
    console.log("click: "+ isPlaying);
    // playerState.isPlaying && 
    !isPlaying
      ? videoElement.current.play().catch(error => {
        //  when an exception is played, the exception flow is followed 
        console.log("error: "+ error);
    })
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  useLayoutEffect(()=> { startPlaying && togglePlay(); }, [startPlaying]);

  const seekhandler = (event) => {  
		var percent = event.nativeEvent.offsetX /  event.target.offsetWidth;
		let newCurrentTime = percent * 100;
    handleSeekVideoProgress(parseFloat(newCurrentTime) );
	}

  const handleOnTimeUpdate = () => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    const currentTimeFormated = videoElement.current.currentTime;
  
    setPlayerState({
      ...playerState,
      progress,
      currentTimeFormated,
    });
   
  };
  // const isFullScreen = ()  =>{
  //   return !!(document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement || document.fullscreenElement);
  // }

  const  openFullscreen = () => {
    var  elem = videoElement.current;
    // if (VideoPlayer.requestFullscreen){
    //     VideoPlayer.requestFullscreen();
    // } else if (VideoPlayer.webkitRequestFullscreen) { /* Safari */
    //     VideoPlayer.webkitRequestFullscreen();
    // } else if (VideoPlayer.msRequestFullscreen) { /* IE11 */
    //     VideoPlayer.msRequestFullscreen();
    // }
    // if (isFullScreen()) {
    //   if (document.exitFullscreen) document.exitFullscreen();
    //   else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    //   else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen();
    //   else if (document.msExitFullscreen) document.msExitFullscreen();
    //   setFullscreenData(false);
    // } else {
    //     if (videoContainer.requestFullscreen) videoContainer.requestFullscreen();
    //     else if (videoContainer.mozRequestFullScreen) videoContainer.mozRequestFullScreen();
    //     else if (videoContainer.webkitRequestFullScreen) videoContainer.webkitRequestFullScreen();
    //     else if (videoContainer.msRequestFullscreen) videoContainer.msRequestFullscreen();
    //     setFullscreenData(true);
    // }
    if (!document.fullscreenElement && !document.mozFullScreenElement &&
      !document.webkitFullscreenElement && !document.msFullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  function toggleFullscreen(elem) {
    elem = elem || document.documentElement;
  
    
  }
  
  // document.getElementById('btnFullscreen').addEventListener('click', function() {
  //   toggleFullscreen();
  // });
  
  // document.getElementById('exampleImage').addEventListener('click', function() {
  //   toggleFullscreen(this);
  // });

  const handleCurrentValueFormat = (value) => {
	let current_minute = parseInt(value / 60) % 60,
		current_seconds_long = value % 60,
		current_seconds = current_seconds_long.toFixed(),
		currentTimeFormated = (current_minute < 10 ? "0" + current_minute : current_minute) + ":" + (current_seconds < 10 ? "0" + current_seconds : current_seconds);
        return currentTimeFormated;
    }

  const handleSeekVideoProgress = (offset) => {
      const manualChange = Number(offset);
      videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
     
      setPlayerState({
        ...playerState,
        progress: manualChange,
      });
  };

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
   
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  };

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value);
    videoElement.current.playbackRate = speed;
    setPlayerState({
      ...playerState,
      speed,
    });
  };

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  };

  const volumeLevelHandler = (event, uiData) => {

    const videoVolume = videoElement.current;
    const volumeBarSize = document.getElementById('volume').getBoundingClientRect();
    let UIX =  event.clientX-volumeBarSize.left;
    let finalPosition = UIX >= 100 ? 100 :
                        UIX <= 0 ? 0 :
                        UIX;
    let volumeLevel = finalPosition/100;

    setVolumeDragState({
        deltaPosition: {
            x: finalPosition,
        }
    });

    videoVolume.volume = parseFloat(volumeLevel);
  }


  useEffect(() => {
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  return {
    playerState,
    setPlayerState,
    togglePlay,
    handleCurrentValueFormat,
    handleSeekVideoProgress,
    seekhandler,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    isVolumeActive,
    volumeHandler,
    volumeLevelHandler,
    volumeDragState,
    openFullscreen,
    toggleMute,
  };
};

export default useVideoPlayer;