import React from 'react';
// import Link from "../Link.js";
import {NavLink} from 'react-router-dom';
function NavItemDropDown({dropdown, mobileNavClickHandler = null}){
  
    if(mobileNavClickHandler == null){
  
        return <div className="dropdown-content">
                    { dropdown.map( (dropNav, index) => (
                        <NavLink to={"/"+dropNav.slug} key={index+"broadcast"}>{dropNav.broadcastNumber}</NavLink>
                    ))}
                </div>
    }else{
        return <div className="dropdown-content">
                { dropdown.map( (dropNav, index) => (
                    <NavLink to={"/"+dropNav.slug} key={index+"broadcast"}  onClick={ mobileNavClickHandler}>{dropNav.broadcastNumber}</NavLink>
                ))}
            </div>
    }
}

export default NavItemDropDown;