import React from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from "react-router-dom";
import VideoSlotMachine from '../Components/VideoSlotMachine/VideoSlotMachine-alt.js';
import VideoPlayList from '../Components/VideoPlayList/VideoPlayList.js';


const GET_Broastcast = gql`query GetBroadcastCollectionBySlug($slug: String!){
  broadcastCollection(where: { slug: $slug }) {
    items {
      name
      broadcastNumber
      releaseLink
      excludeFromReleasePage
      releaseLayout
      releaseText
      releaseDate
      releaseType
      component
      videoDuration
      releaseArtwork {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      navigationOptions
      metaTitle
      metaDescription
      slug
      layoutOptions
      additionalData
      codeCollection(limit: 6) {
        items {
          name
          description
          codeType
          insertLocation
          replaceBaseCode
          codeUrl
          embed
        }
      }
      thumbnail {
        title
        description
        contentType
        fileName
        size
        url
        width
        height
      }
      streamUrl
      mp4
      webm
      songTitlesCollection(limit: 30) {
        items {
          id
          songTitle
          artist
          startTime
          endTime
          startTimeSecondsDecimal
          itunesUrl
        }
      }
    }
  }
}`;


function Broadcast({ saveToPlayHandler, isSaveToPlay, hasAPageLoaded, updateNavVisablity  }){

 
    let location = useLocation();
   
    let SLUG = location.pathname.replace('/', '')
    console.log(location +', '+ SLUG);

    let { loading, error, data } = useQuery(GET_Broastcast, { variables: { "slug": SLUG }
    });
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    console.log(data);
    let Broadcast = data.broadcastCollection.items[0];


    return <div className="page-wrapper max-width Player-max-width">
                <Helmet>
                    <title>{Broadcast.name}</title>
                    <meta name="description" content={Broadcast.metaDescription} />
                    <meta name="title" content={Broadcast.metaTitle} />
                    <meta name="robots" content="index, follow"></meta>
                </Helmet>
           

                {
                   Broadcast.component === "VideoSlotMachine" ? <VideoSlotMachine  navigationOptions={Broadcast.navigationOptions} updateNavVisablity={updateNavVisablity} isSaveToPlay={isSaveToPlay} ></VideoSlotMachine> :
                   Broadcast.component === "VideoPlayList" ? <VideoPlayList data={data}  navigationOptions={Broadcast.navigationOptions} updateNavVisablity={updateNavVisablity} saveToPlayHandler={ saveToPlayHandler} isSaveToPlay={isSaveToPlay} hasAPageLoaded={hasAPageLoaded}></VideoPlayList> :
                   <div></div>
                }
               
            </div>

}
export default Broadcast;
