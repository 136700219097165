import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider
} from "@apollo/client";
import {
  BrowserRouter as Router,
} from 'react-router-dom';

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_ID;
const CONTENFUL_API_KEY = process.env.REACT_APP_CONTENTFUL_KEY;
const CONTENTFUL_URL = 'https://graphql.contentful.com/content/v1/spaces/'+SPACE_ID+'/?access_token='+CONTENFUL_API_KEY;

const client = new ApolloClient({
  uri: CONTENTFUL_URL,
  cache: new InMemoryCache()
});




ReactDOM.render(
  <ApolloProvider client={client} >
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();