import React,{ useRef} from 'react';
import ReactHlsPlayer from 'react-hls-player';
import useVideoPlayer from "../Hooks/useVideoPlayer.js";
import "./VideoControls.css";
// import Draggable from 'react-draggable';
import Draggable from 'react-draggable';
function VideoBox({broadcast, startPlaying, isFullScreen, isMobile, hasAPageLoaded, isSaveToPlay, videoBoxNewHeight}){

      let shouldIPLay = 0;
   React.useEffect(() => {
        shouldIPLay =  isSaveToPlay && hasAPageLoaded ? 1 : 0;
    }, [])
    const videoElement = useRef(null);
    const {
        playerState,
        togglePlay,
        handleOnTimeUpdate,
        isVolumeActive,
        volumeLevelHandler,
        volumeHandler,
        volumeDragState,
        openFullscreen,
        handleVideoProgress,
        seekhandler,
        handleCurrentValueFormat,
    } = useVideoPlayer(videoElement, shouldIPLay, startPlaying);

    
   
    // saveToPlayHandler, prevLocation, location
    // prevLocation !== location?  saveToPlayHandler(true) :  saveToPlayHandler(false);
    let videoComps = isFullScreen ? "videoComps fullscreen" : "videoComps";
    let volumeLevel =  "calc("+volumeDragState.deltaPosition.x+"% - 16px)";
    let ShowVolume = isVolumeActive ? "ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all" : "hidden ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all";
    // let VideoContainerClass = isSaveToPlay && hasAPageLoaded ? "video-container visable" : "video-container opacityHidden" ;
    return 	<div className={videoComps} style={{height: videoBoxNewHeight }}>
                <div className={"video-container"} >
                    <ReactHlsPlayer
                            playerRef={videoElement}
                            onTimeUpdate={handleOnTimeUpdate}
                            controls={false}
                            src={"https://static.peel.global/streams/broadcasts/"+broadcast.streamUrl}
                            onClick={togglePlay}
                            playsInline
                        />
                </div>
                <div className="holder">
                <div className="progress-holder">
                    <div className="slider" data-direction="horizontal">
                        <div className="progress">
                            <progress id="progress-pin" value={playerState.isPlaying ||  isSaveToPlay ? playerState.progress : 0} max="100" className="seekObj pin progress-bar"  onClick={(event) => {  seekhandler(event)}} onChange={(e) => handleVideoProgress(e)}></progress>
                        </div>
                    </div>
                </div>
                <div className="video-player">
                    <div className="left-controls">
                        <div className="play-pause-btn" onClick={() => {togglePlay();}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 18 24" >
                                <path fill="#ffffff" fillRule="evenodd" d={ playerState.isPlaying ?  "M0 0h6v24H0zM12 0h6v24h-6z"  : "M18 12L0 24V0"} className="play-pause-icon" id="playPause"/>
                            </svg>
                        </div>
                        <div className="controls">
                            <span className="current-time">{handleCurrentValueFormat(playerState.currentTimeFormated)}</span>
                            <span className="time-divider"> / </span>
                            <span className="total-time">{broadcast.videoDuration}</span>
                        </div>
                    </div>
                    <div className="middle-controls"></div>
                    <div className="right-controls">
                       <div id="volume" className={ShowVolume}>
                           <div className="ui-slider-range ui-widget-header ui-corner-all ui-slider-range-min" style={{ width: volumeLevel}} ></div> 
                           <Draggable axis="x" handle="#VolumeHandle" onDrag={(event, uiData) => volumeLevelHandler(event, uiData)} bounds="parent" >
                               <span id="VolumeHandle" className="ui-slider-handle ui-state-default ui-corner-all"  ></span>
                            </Draggable> 
                        </div>
                        <div className="volume" onClick={volumeHandler}>
                            <div className="volume-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 24 24">
                                    <path fill="#ffffff" fillRule="evenodd" d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z" id="speaker"/>
                                </svg>
                            </div>


                        </div>
                        <div id="fullscreen" className="icon-fullscreen" aria-label="enter full screen" onClick={openFullscreen}></div>
                    </div>
                </div>
            </div>
    </div>
}
export default VideoBox;
