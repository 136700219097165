import React, { useState } from 'react';
import './Navigation.css';
import Logo from './Logo.js';
import NavItems from './NavItem.js';
import { gql, useQuery } from '@apollo/client';

function Navigation({isNavVisable, hideLogo}){
    const [isMobileNavActive, setIsMobileNavActive] = useState(false);
    const mobileNavClickHandler = function(){  
        setIsMobileNavActive(isMobileNavActive => !isMobileNavActive);
    }
    let DropDownQuery; 
    DropDownQuery = gql`{
                broadcastCollection{
                items{
                name
                broadcastNumber
                slug
                }
            }
            }
            `;

    const { loading, error, data } = useQuery(DropDownQuery);
    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;
    
    const dropDown = data.broadcastCollection.items;
    // console.log(data);
 
    

    return   <div className={isMobileNavActive ? "max-width navi-max-width is-active" : "max-width navi-max-width"}>

                <div className={isNavVisable ? "page-nav-container" : "page-nav-container hovernav" }>
                    {hideLogo ?  <Logo newClass={"FadeOutLogo"}/> : <Logo newClass={"FadeInLogo"}/>}
                    <NavItems className="left-nav-container left-nav-container-five row mobile-gone" dropdown={dropDown}></NavItems>
                </div>
                <div className={isMobileNavActive && isNavVisable? "left-nav-container left-nav-container-five row mobil-show mob-nav is-active" : 
                                !isNavVisable ? "left-nav-container left-nav-container-five row mobil-show mob-nav hovernav":
                               "left-nav-container left-nav-container-five row mobil-show mob-nav" } >
                            <div className={isMobileNavActive ? "topNav navburger navburger--spin is-active" : "topNav navburger navburger--spin"} onClick={mobileNavClickHandler}>
                                <div className="navburger-box">
                                    <div className="navburger-inner"></div>
                                </div>
                            </div>
                            <NavItems className="mobileNavItems"  dropdown={dropDown} mobileNavClickHandler={mobileNavClickHandler}></NavItems>
                </div>
            </div>
}

export default Navigation;

