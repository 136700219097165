import React, {useState, useEffect, useRef} from 'react';
import { Helmet } from 'react-helmet';
import { motion } from "framer-motion";
// import { useHistory } from 'react-router-dom';
import NewsNav from '../Components/NewsNav/NewsNav.js';
import useDeviceCheck from '../Components/Hooks/useDeviceCheck';
import ReleaseMobile from '../Components/NewsArticle/ReleaseMobile';
import Article from '../Components/NewsArticle/Article';
import useFetchNews from '../Components/NewsNav/useFetchNews';
import useSearchEngineVisable from '../Components/Hooks/useSearchEngineVisable';
import MobileContainer from '../Components/NewsArticle/MobileContainer';
import ReleasesComponent from '../Components/Releases/ReleasesComponent';

function News({ now, updateNavVisablity, isSubPage }){
    // data
    useEffect(() => {
        updateNavVisablity( "Normal");
        }, [updateNavVisablity]);

    const newsNav = useRef();
    const newsArtical = useRef();
    const [TodaysDate, getTodaysDate] = useState('');
    const [initState, getinitState] = useState(0);
    const mobileRef = useRef(null);
    function scrollTo(ref) {
        if (!ref.current) return;
        ref.current.scrollIntoView({ behavior: "smooth" });
      } 

    useEffect(() => {

        function getTheCurrentDate(){
            var d = now.current;
            var zeros =  d.setUTCHours(0,0,0,0); 
            var todayDate = new Date(zeros).toISOString();
            return todayDate;
        }
        let CurrentDate = getTheCurrentDate();
        // CurrentDate !== TodaysDate &&  
        getTodaysDate(CurrentDate);
      
      }, [ now]);

      const [ News,
        Press,
        Releases,
        Upcoming,
        data
    ] = useFetchNews(TodaysDate);
      
    let [isMobile] = useDeviceCheck();

    // console.log("News:" + JSON.stringify(data));
    // console.log("ReleaseMerge:", JSON.stringify(Releases));
    // let history = useHistory();
    const [accordionState, setAccordionState] = useState({ isActive: false, currentID: '', previousID: ''});
    const [intialLoadActive, setintialLoadActive] = useState({ isActive: false, needsInt: true, currentID: ''});
    const [isHoovering, setIsHoovering] = useState({ isActive: false, currentID: '', previousID: ''});
    const [isHooverShow, setIsHooverShow] = useState({ isActive: false, currentID: '', previousID: ''});

    const accordionActiveHandler = function( currentID){    


            console.log("Current ID: " +currentID);
            let previousCurrent = accordionState.currentID;
            if(currentID === accordionState.currentID){
                setAccordionState({...accordionState, isActive: !accordionState.isActive, currentID: '', previousID: currentID});
            }else if(accordionState.currentID === '' && !accordionState.isActive){
                setAccordionState({ ...accordionState,isActive: true, currentID: currentID,previousID: ''});
            }else{
                setAccordionState({...accordionState,isActive: true, currentID: currentID, previousID: previousCurrent });
            }

       
    }
   
    // const offClickHandle = function( e){    
    //     if(newsNav.current.contains(e.target) || newsArtical.current.contains(e.target)) {
    //         let currentPage = window.location.pathname;
           
    //         if(currentPage.length >= 7){
               
    //             history.push("/news");
    //             setintialLoadActive({...intialLoadActive, isActive: false, currentID: ""});
    //         }
     
    //         console.log('off click with target'+ e.target.className);
      
          
    //     }
      

    // }
   
    const accordionHooveringHandler = function( activeStatus = false, theCurrentID = '',  previousCurrent = ''){
        setIsHooverShow({...isHooverShow, isActive: activeStatus, currentID: theCurrentID, previousID: previousCurrent });
        // history.push("/news/"+theCurrentID);
    }
  
    const simpleHooveringHandler = function(activeStatus = false, theCurrentID = '',  previousCurrent = ''){
        setIsHoovering({...isHoovering, isActive: activeStatus, currentID: theCurrentID, previousID: previousCurrent });
       
    }

    useEffect(() => {
        let currentPage = window.location.pathname;
        let activePath = currentPage.replace('/news/', '');
        if(initState === 0){
            
            if(currentPage.length >= 7 && intialLoadActive.needsInt === true){
           
                
                console.log("activePath", activePath);
                setintialLoadActive({...intialLoadActive, isActive: true, currentID: activePath});
                getinitState(1);
                if(isMobile){
                    scrollTo(mobileRef)
                }
               
            }
        }else{
            console.log("activePath", activePath);
            setintialLoadActive({...intialLoadActive, isActive: false, currentID: 'activePath'});
        }
      
      }, [initState]);

      
    
    // useEffect(() => {
    //     // add when mounted
    //     document.addEventListener("mousedown", offClickHandle);
    //     // return function to be called when unmounted
    //     return () => {
    //       document.removeEventListener("mousedown", offClickHandle);
    //     };
    //   }, []);
    

    // console.log("News:" + JSON.stringify(News));
    // console.log("pressCollection:" + Press);
    // console.log("Releases:" + Releases);
   
   const [ VisableResponse ] = useSearchEngineVisable(News);

    const pageVariants = {
        hidden: {
          opacity: 0
        },
        visible: {
          opacity: 1,
          transition: {
            delay: .2
          }
        }
      };
      const ExitVar = { opacity: 0, transition: { duration: 1, ease: [0.87, 0, 0.13, 1]} };

      if(TodaysDate !== undefined && News !== null){

        const articles = TodaysDate !== undefined && Press !== null && Press.length  !== 1 ? Press.map((article, articleIndex) => ( <Article  mobileRef={mobileRef} article={article} title={article.title} isHoovering={isHooverShow} intialLoadActive={intialLoadActive} isMobile={isMobile} TodaysDate={TodaysDate} key={articleIndex}/>  )) : null;
        const mobileReleaseContent = TodaysDate !== undefined && Releases !== null && Releases.length  !== 1 && isMobile ? Releases.map((releaseMobile, releaseMobileIndex) => ( <ReleaseMobile  release={releaseMobile} intialLoadActive={intialLoadActive} isHoovering={isHooverShow} TodaysDate={TodaysDate} key={releaseMobileIndex}/> )) : null;
        const ReleaseContent = TodaysDate !== undefined && Releases !== null && Releases.length  !== 1 ? Releases.map((release, releaseIndex) => ( <ReleasesComponent release={release} isHoovering={isHooverShow} intialLoadActive={intialLoadActive} key={releaseIndex}/> )) : null;
        console.log('isMobile?: '+ isMobile);

        return <div className="page-wrapper max-width News-max-width">
                    <Helmet>
                        <title>{News.metaTitle}</title>
                        <meta name="description" content={News.metaDescription}/>
                        <meta name="title" content={News.metaTitle} />
                        <meta name="robots" content={VisableResponse}></meta>
                    </Helmet>
                    <div className="newsPageContainer" ref={newsNav}>
                        <motion.div exit={ExitVar} initial="hidden" animate="visible" variants={pageVariants} className={"fadeFullWidth"}>
                            <NewsNav upcoming={Upcoming} 
                                     releases={Releases} 
                                     press={Press} 
                                     accordionState={accordionState} 
                                     accordionActiveHandler={accordionActiveHandler} 
                                     TodaysDate={TodaysDate}
                                     accordionHooveringHandler={accordionHooveringHandler}
                                     isHoovering={isHoovering}
                                     simpleHooveringHandler={simpleHooveringHandler}
                                     intialLoadActive={intialLoadActive}
                                     ></NewsNav>
                        </motion.div>
                        <div className="articleSpacer"></div>
                        <div className="newsArticleContainer" ref={newsArtical}>
                                    <div className="releaseArticleContainer">
                                        {
                                         Releases !== null ?   ReleaseContent : null
                                        }
                                        
                                    </div>
                                    <div className="articleContainer">
                                        {
                                           articles !== null && !isMobile ? articles : null
                                        }
                                    </div>
                        </div>
                            {
                               
                                //articleSpacer
                                //newsArticleContainer
                                        // releaseArticleContainer
                                        // articleContainer
                            }   
                    </div>
                  
                    { articles !== null && isMobile ? <MobileContainer TodaysDate={TodaysDate} articlesContent={articles} releaseContent={mobileReleaseContent} mobileRef={mobileRef} /> : null }
                    {
                        //<div className="newsContainerArticles">{articles}{mobileReleaseContent}</div>
                    }

                </div>
    }else{
        return <div className="page-wrapper max-width News-max-width"></div>;
    }

}
export default News;