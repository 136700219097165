import React from 'react';
import Marquee from "react-fast-marquee";
function MusicPlayerScrollingText(props){

        let duration = (props.song.spotifyURl !== null) ? "00:30" : props.song.duration;
        let shortTile = props.song.playerShortTitle;
        let songTile = props.song.songTitle;
        let movingClass = "moving-text song-"+shortTile;
        let speed = props.song.playerMarqueeSpeed * 25;
        let offset = props.song.playerOffsetPrecent*50;
     
    return <div id={shortTile+'NP'} className="notplayer" data-video={props.song.nameLowerCase} data-duration={duration} data-shorttitle={shortTile} key={props.index+'-NP'} onClick={(event) => {props.onSongActivation(event,props.song.nameLowerCase)}}>
                <div className={movingClass} data-marquespeed={props.song.playerMarqueeSpeed} data-offsetpercent={"-"+offset}>
                    <div className="scoll-holder">
                    <Marquee className="sub-scoll-holder sub-scoll-holder-1"  gradient={false} speed={speed} delay={"-"+offset}>
                            <span className={"scroll-text song-"+shortTile+"-1 song-"+shortTile+"-scroller"}>{songTile}</span>
                            <span className="spacer spacer-1"></span>
                    </Marquee>
                    </div>
                </div>
                <div className="hover-text" >
                    <span className="linebreak">Play</span>
                    <span className="linebreak lbtitle" data-title={props.song.nameLowerCase} >{songTile}</span>
                </div>
            </div>
}
export default MusicPlayerScrollingText;