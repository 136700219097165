

import React from 'react';
import Gif from '../Layouts/Gif';
import ResponsiveImage from '../Layouts/ResponsiveImage';
function ReleasesComponent({release, isHoovering}){
  
    let slug = release.title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').replace(/\s+/g, '-').toLowerCase().trim();
    let simpleSlug = release.__typename.toLowerCase().trim() + "-" + slug;
    let Layout =  release.releaseLayout === "gif" ? <Gif release={release}/> : <ResponsiveImage release={release}/>;
    let theClass = isHoovering.isActive && isHoovering.currentID === simpleSlug
      ? "articleContainer active"
      : "articleContainer";
    return  <div className={theClass} data-navlink={simpleSlug}>
                <a href={release.releaseLink} className="external">
                    {Layout}
                </a>
            </div>
    
   

}
export default ReleasesComponent;