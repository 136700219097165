import React from 'react';
import useDeviceCheck from '../Hooks/useDeviceCheck';

import {Link} from 'react-router-dom';
const NewsAccordionItem = ({ title, sup, outgoing, articleID, typeName, accordionHooveringHandler}) => {
    function truncate(str, n){
        return (str.length > n) ? str.substr(0, n-1) + ' ...' : str;
      };
    
    let IDArticle = articleID.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').replace(/\s+/g, '-').toLowerCase();
    let slug = title.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').replace(/\s+/g, '-').toLowerCase().trim();
    let simpleSlug = typeName.toLowerCase().trim()+"-"+slug;
    let [isMobile] = useDeviceCheck();  
    
    let TheLink = isMobile || typeName === "Press" ? (<Link to={"/news/"+simpleSlug} className="external"><span className="Label newsBarTitle">{'"'+truncate(title, 23)+'"'}</span><sup className="time-column newsBarPublication">{sup}</sup></Link>) :
                                                     (<a href={outgoing} className="external" > <span className="Label newsBarTitle">{truncate(title, 22)}</span><sup className="time-column newsBarPublication">{sup}</sup></a>);

        return  <div className="accordion-content-row row newsContentRow" 
                onMouseEnter={() => { !isMobile && accordionHooveringHandler(true, simpleSlug) }} 
                onMouseLeave={() => {  !isMobile && accordionHooveringHandler(false, '', simpleSlug) }}
               
                data-articleid={IDArticle}>
                    <span className="checkoutIcon icon-peel-arrow-glyph"></span>
                    {TheLink}
                </div>

}
export default NewsAccordionItem;