
import React from 'react';
function VideoThumbnail({ image, id, onClick, className, updateHeightHandler}){
    const videImage = React.useRef();
    React.useEffect(() => {
        // add when mounted
       if(videImage.current !== null){
            let newHeight = videImage.current.height;
            console.log('image Height:' + newHeight)
            updateHeightHandler(newHeight);
       }
      }, [updateHeightHandler, videImage]);

    return  <img id={id}  ref={videImage} onClick={onClick} sizes="(max-width: 640px) 100vw, 640px" src={image.url+"?w=640"} alt={image.description} className={className} style={{maxWidth: '640px'}} srcSet={image.url+"?w=240 240w,"+image.url+"?w=320 320w,"+image.url+"?w=480 480w,"+image.url+"?w=640 640w,"+image.url+"?w=750 750w,"+image.url+"?w=1024 1024w,"+image.url+"?w=2048 2048w"}/>
    


}
export default VideoThumbnail;
