import React from 'react';
import { Helmet } from 'react-helmet';
import VideoMusicPlayer from '../Components/VideoMusicPlayer/VideoMusicPlayer.js';
import { gql, useQuery } from '@apollo/client';
import { motion } from "framer-motion";

const GET_HomePage = gql`{
  landingPageCollection(limit: 1) {
    items {
      name
      metaDescription
      metaTitle
      page {
        name
        pageType
        metaDescription
        navigationOptions
        layoutOptions
        metaTitle
        isPageVisibleToSearchEngines
      }
    }
  }
  albumCollection(where: {name: "Peel"}){
       items {
      songCollection(order: [trackNumber_ASC
            ] ) {
        items {
            trackNumber
            songTitle
            nameLowerCase
            playerShortTitle
            duration
            spotifyURl
            itunesUrl
            playerMarqueeSpeed
            playerOffsetPrecent
                }
            }
        }
    }
  # albumSongsCollection(order: [trackNumber_ASC]) {
  #   items {
  #       trackNumber
  #       songTitle
  #       nameLowerCase
  #       playerShortTitle
  #       duration
  #       spotifyURl
  #       itunesUrl
  #       playerMarqueeSpeed
  #       playerOffsetPrecent
  #   }
  # }
}
`;

function PlayerPage({updateNavVisablity}){
  React.useEffect(() => {
    updateNavVisablity( "Normal");
    }, [updateNavVisablity]);
    
  const { loading, error, data } = useQuery(GET_HomePage);
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const HomeMeta = data.landingPageCollection.items[0];
  const MusicData = data.albumCollection.items[0].songCollection;
  console.log(data);
  let isPageVisibleToSearchEngines = HomeMeta.page.isPageVisibleToSearchEngines;
  let VisableResponse = (isPageVisibleToSearchEngines == null || !isPageVisibleToSearchEngines) ? 'noindex, nofollow' : 'index, follow';

  const pageVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 1
      }
    }
  };
  const ExitVar = { opacity: 0, transition: { duration: 1.5, ease: [0.87, 0, 0.13, 1]} };

  return <div className="page-wrapper max-width Player-max-width">
              <Helmet>
                  <title>{HomeMeta.name}</title>
                  <meta name="description" content={HomeMeta.metaDescription} />
                  <meta name="title" content={HomeMeta.metaTitle} />
                  <meta name="robots" content={VisableResponse}></meta>
              </Helmet>
              <motion.div exit={ExitVar} initial="hidden" animate="visible" variants={pageVariants}>
                <VideoMusicPlayer songData={MusicData}> </VideoMusicPlayer>
              </motion.div>
          </div>

}

export default PlayerPage;