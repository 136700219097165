
import React from 'react';

function ResponsiveImage({release}){
    return <img srcSet={release.releaseImage.url+"?w=240 240w,"+release.releaseImage.url+"?w=320 320w,"+release.releaseImage.url+"?w=480 480w,"+release.releaseImage.url+"?w=640 640w,"+release.releaseImage.url+"?w=750 750w,"+release.releaseImage.url+"?w=1024 1024w,"+release.releaseImage.url+"?w=2048 2048w"}
                src={release.releaseImage.url+"?w=588"}
                alt={release.title}
                title={release.text} 
                sizes="(max-width: 588px) 100vw, 588px"
                style={{maxWidth: '588px'}}
                />
}
export default ResponsiveImage;