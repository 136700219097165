import React from 'react';
function BroadCastListingItem(props){
   
    let onUpdateVideoTime = props.clickHandler;
    const broadcastListItem = props.listing;

    //
    let math = parseInt(props.length) - 2;
    let seperator = (props.index <= math) ? "  /  " : "";
    console.log(props.index +" <= " +math);
             
    return <div className="listing-item">
        
                        <span className="track first-track" onClick={() => {  onUpdateVideoTime(broadcastListItem.startTimeSecondsDecimal);} }>
                            <span className="time-column">{broadcastListItem.startTime+' - '+ broadcastListItem.endTime}</span>
                            <span className="artist-column">
                                <span className="bold band">{broadcastListItem.artist}</span> - <span>{broadcastListItem.songTitle}</span>
                            </span>
                        </span>
                        <span className="track-seperator"> {seperator} </span>
                </div>
}
export default BroadCastListingItem;