import React from 'react';
import './MusicPlayer.css';
import MusicPlayerSong from './MusicPlayerSong.js';

function MusicPlayer(props){

    let Songs = props.music;
    let songActiveToogleHandler = props.onSongActivation;
	let isPlayerActive = props.activationState;
    return <div className="music-box-container">
            { Songs.items.map( (songData, songIndex) => (
                
                                <MusicPlayerSong songs={songData} 
                                                 key={songIndex} 
                                                 onSongActivation={songActiveToogleHandler}
                                                 activationState={isPlayerActive}
                                                 videoPath={props.videoPath}
                                                 />)) }
                
            </div>
}

export default MusicPlayer;